var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"kkp-mobile-app-modal","hide-footer":"","size":"xl","title":"ลงทะเบียนระบบออโต้ KKP Mobile App"}},[_c('b-overlay',{attrs:{"show":_vm.isFetching}},[(_vm.step === 1)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendOtp)}}},[_c('ValidationProvider',{attrs:{"name":"idCard","rules":"required|length:13|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เลขบัตรประจำตัวประชาชน"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"13","placeholder":"เลขบัตรประจำตัวประชาชน","type":"text"},model:{value:(_vm.requestOtpForm.idCard),callback:function ($$v) {_vm.$set(_vm.requestOtpForm, "idCard", $$v)},expression:"requestOtpForm.idCard"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"pin","rules":"required|length:6|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"พิน 6 หลัก"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"6","placeholder":"123456 PIN","type":"text"},model:{value:(_vm.requestOtpForm.pin),callback:function ($$v) {_vm.$set(_vm.requestOtpForm, "pin", $$v)},expression:"requestOtpForm.pin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto",attrs:{"disabled":_vm.isFetching,"type":"submit","variant":"primary"}},[_vm._v(" ส่ง OTP ")])],1)],1)]}}],null,false,338259133)}):_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.verifyOtp)}}},[_c('ValidationProvider',{attrs:{"name":"otp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"OTP"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"disabled":_vm.isFetching,"state":errors[0] ? false : null,"maxlength":"6","min":"6","placeholder":"OTP","type":"text"},model:{value:(_vm.otpForm.otp),callback:function ($$v) {_vm.$set(_vm.otpForm, "otp", $$v)},expression:"otpForm.otp"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-button',{attrs:{"disabled":_vm.isFetching,"type":"submit","variant":"success"}},[_vm._v(_vm._s(_vm.$t('buttons.submit')))]),_c('b-button',{staticClass:"ml-2",attrs:{"disabled":_vm.isFetching,"type":"button","variant":"outline-primary"},on:{"click":function (e) { return _vm.sendOtp(); }}},[_vm._v(" "+_vm._s(_vm.$t('buttons.resend'))+" ")]),_c('b-button',{staticClass:"ml-2",attrs:{"disabled":_vm.isFetching,"type":"button","variant":"light"},on:{"click":function($event){_vm.step = 1}}},[_vm._v(" แก้ไขข้อมูล ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }