<template>
  <b-modal
      id="kkp-mobile-app-modal"
      hide-footer
      size="xl"
      title="ลงทะเบียนระบบออโต้ KKP Mobile App"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-if="step === 1" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(sendOtp)">
        <ValidationProvider
            v-slot="{ errors }"
            name="idCard"
            rules="required|length:13|numeric"
        >
          <b-form-group label="เลขบัตรประจำตัวประชาชน">
            <b-form-input
                v-model="requestOtpForm.idCard"
                :aria-invalid="false"
                :state="errors[0] ? false : null"
                maxlength="13"
                placeholder="เลขบัตรประจำตัวประชาชน"
                type="text"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" name="pin" rules="required|length:6|numeric">
          <b-form-group label="พิน 6 หลัก">
            <b-form-input
                v-model="requestOtpForm.pin"
                :aria-invalid="false"
                :state="errors[0] ? false : null"
                maxlength="6"
                placeholder="123456 PIN"
                type="text"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <div class="d-flex">
          <b-button :disabled="isFetching" class="ml-auto" type="submit" variant="primary">
            ส่ง OTP
          </b-button>
        </div>
      </form>
    </ValidationObserver>
    <ValidationObserver v-else v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(verifyOtp)">
        <ValidationProvider v-slot="{ errors }" name="otp" rules="required">
          <b-form-group label="OTP">
            <b-form-input
                v-model="otpForm.otp"
                :aria-invalid="false"
                :disabled="isFetching"
                :state="errors[0] ? false : null"
                maxlength="6"
                min="6"
                placeholder="OTP"
                type="text"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <b-button :disabled="isFetching" type="submit" variant="success">{{
            $t('buttons.submit')
          }}</b-button>
        <b-button :disabled="isFetching" class="ml-2" type="button" variant="outline-primary" @click="(e) => sendOtp()">
          {{ $t('buttons.resend') }}
        </b-button>
        <b-button :disabled="isFetching" class="ml-2" type="button" variant="light" @click="step = 1">
          แก้ไขข้อมูล
        </b-button>
      </b-form>
    </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'KkpAutoMobileAppModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    kkpAccount: {
      type: Object,
      default: () => ({
        idCard: '',
        pin: '',
      })
    }
  },
  data() {
    return {
      step: 1,
      requestOtpForm: {
        idCard: '',
        pin: '',
      },
      otpForm: {
        otp: '',
        ref: '',
        transactionId: '',
      },
      isFetching: false
    }
  },
  created() {
    this.requestOtpForm = {
      idCard: this.kkpAccount?.idCard || '',
      pin: this.kkpAccount?.pin || '',
    }
  },
  methods: {
    ...mapActions(['fetchBankAccount', 'requestKKPOtp', 'submitKKPOtp']),
    async sendOtp() {
      this.isFetching = true
      const response = await this.requestKKPOtp({ id: this.agentBankAccountId, data: this.requestOtpForm })
      if(response) {
        this.step = 2
        this.otpForm.ref = response.ref
        this.otpForm.transactionId = response.transactionId
      }
      this.isFetching = false
    },
    async verifyOtp() {
      this.isFetching = true
      const response = await this.submitKKPOtp({
        id: this.agentBankAccountId,
        data: {
          ...this.requestOtpForm,
          ...this.otpForm,
        }
      })
      if(response) {
        this.step = 1
        this.$bvModal.hide('kkp-mobile-app-modal')
        await this.fetchBankAccount(this.agentBankAccountId)
      }
      this.isFetching = false
    }
  }
})
</script>

<style scoped>

</style>